import { Plugins } from '@capacitor/core';
import { OIDCFrog } from '@livingapps/oidcfrog';
import env from './env';
import { OfflineError, UserNotLoggedInError } from './errors';
import { deleteDB } from '@livingapps/oidcfrog/dist/shared/idb';
const { Browser } = Plugins;
const client = new OIDCFrog({
    clientId: env.clientId,
    discoveryEndpoint: env.discoverEndpoint,
    redirectUrlObj: {
        login: env.loginRedirect,
        logout: env.logoutRedirect,
    },
});
/**
 * login a user
 * @throws {OfflineError}
 */
export async function login() {
    console.time('login');
    console.info('[OIDC] login user');
    console.group();
    try {
        await client.discover();
        console.timeEnd('login');
        console.info('[OIDC] login user');
        console.groupEnd();
        if (env.iOsOnly) {
            await Browser.open({
                url: `${client.endpointObj.authorizationEndpoint}?client_id=${client.clientId}&response_type=code&scope=openid&redirect_uri=${client.redirectUrlObject.login}`,
            });
        }
        else {
            client.login();
        }
    }
    catch (_a) {
        console.timeEnd('login');
        console.groupEnd();
        throw new OfflineError();
    }
}
/**
 * @throws {OfflineError}
 */
export async function logout() {
    console.time('logout');
    console.info('[OIDC] logout user');
    console.group();
    try {
        await client.discover();
        if (env.iOsOnly) {
            console.info('[OIDC - iOS] logout user', `${client.endpointObj.endSessionEndpoint}?client_id=${client.clientId}&id_token_hint=${(await client.getTokenObj()).idToken}`);
            await Browser.open({
                url: `${client.endpointObj.endSessionEndpoint}?client_id=${client.clientId}&&redirect_uri=${client.redirectUrlObject.logout}`,
            });
            console.log('logged out user');
            console.timeEnd('logout');
            await Browser.close();
            await deleteDB();
            window.location.href = 'capacitor://localhost';
            console.groupEnd();
        }
        else {
            console.timeEnd('logout');
            console.groupEnd();
            client.logout();
        }
    }
    catch (error) {
        console.error(error);
        console.timeEnd('logout');
        console.groupEnd();
        throw new OfflineError();
    }
    finally {
        await deleteDB();
    }
}
export async function initOidc() {
    console.time('initOidc');
    console.info('[OIDC] initialize tokens');
    console.group();
    try {
        console.timeEnd('initOidc');
        console.info('[OIDC] Store tokens in db');
        console.groupEnd();
        await client.discover();
        await client.initTokenObj();
    }
    catch (_a) {
        console.timeEnd('initOidc');
        3;
        console.groupEnd();
        throw new OfflineError();
    }
}
/**
 * returns an access token if the user is logged in
 * else it throws OfflineError or UserNotLoggedInError
 * @throws {OfflineError | UserNotLoggedInError}
 */
export async function getValidAccessToken() {
    console.time('getValidAccessToken');
    console.debug('[OIDC] tries to return a valid access token');
    console.group();
    let tokenObj;
    try {
        await client.discover();
    }
    catch (_a) {
        console.timeEnd('getValidAccessToken');
        console.warn('[OIDC] Can not reach oidc provider');
        console.groupEnd();
        throw new OfflineError();
    }
    try {
        tokenObj = await client.getTokenObj();
        console.timeEnd('getValidAccessToken');
        console.debug('[OIDC] return valid access token');
        console.groupEnd();
        return tokenObj.accessToken;
    }
    catch (err) {
        if (err.message === 'refreshtoken expired') {
            console.timeEnd('getValidAccessToken');
            console.warn('[OIDC] Refreshtoken expired');
            console.groupEnd();
            throw new UserNotLoggedInError();
        }
        else {
            try {
                const accessToken = (await client.updateTokenObj()).accessToken;
                console.timeEnd('getValidAccessToken');
                console.debug('[OIDC] return updated accesstoken');
                console.groupEnd();
                return accessToken;
            }
            catch (_b) {
                console.timeEnd('getValidAccessToken');
                console.warn('[OIDC] failed to update accesstoken');
                console.groupEnd();
                throw new UserNotLoggedInError();
            }
        }
    }
}
